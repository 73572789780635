var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{key:_vm.componentKey},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"4","md":""}},[_c('div',{staticClass:"body-title font-weight-bolder"},[_vm._v("Apps")])]),_c('b-col',{attrs:{"col":"","md":""}},[_c('b-input-group',[_c('b-input-group-prepend',[_c('div',{staticClass:"input-group-text bg-white"},[_c('b-icon',{attrs:{"icon":"search","aria-hidden":"true"}})],1)]),_c('b-form-input',{ref:"search",staticClass:"border-left-0",attrs:{"type":"search","placeholder":"Buscar...","disabled":_vm.clearSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('mn-table',{staticClass:"mt-3",attrs:{"items":_vm.items,"headers":_vm.headers,"enabledHeader":""},on:{"push-route":_vm.onPushRoute},scopedSlots:_vm._u([{key:"avatar_url",fn:function(ref){
var item = ref.item;
return [_c('section',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{staticClass:"bg-gray-200 text-body",attrs:{"size":"3em","rounded":"","src":item.avatar_url,"text":_vm._f("upperCase")(_vm._f("truncate")(item.title,2))}}),(item.project_type)?_c('div',{staticClass:"ml-3"},[(item.project_type === 'ios')?_c('icon-apple',{attrs:{"fillColor":"rgba(0, 0, 0, 0.60)","size":24}}):_vm._e(),(item.project_type === 'android')?_c('icon-android',{attrs:{"fillColor":"rgba(0, 0, 0, 0.60)","size":24}}):_vm._e()],1):_vm._e()],1)]}},{key:"path",fn:function(ref){
var item = ref.item;
return [_c('b-input-group',{staticClass:"input-path",attrs:{"size":"sm"}},[_c('b-input-group-prepend',[_c('div',{staticClass:"input-group-text bg-white"},[_vm._v(" / ")])]),_c('b-form-input',{staticClass:"border-left-0",attrs:{"placeholder":"Ingresar path","size":"sm"},model:{value:(item.path),callback:function ($$v) {_vm.$set(item, "path", $$v)},expression:"item.path"}})],1)]}},{key:"actived",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"switch":""},on:{"change":function($event){return _vm.onActived($event, item.slug)}},model:{value:(item.actived),callback:function ($$v) {_vm.$set(item, "actived", $$v)},expression:"item.actived"}})]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"View Builds","variant":"link","size":"sm","to":{ name: 'admin.builds', params: { app_slug: item.slug } }}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'eye'],"size":"lg"}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Save","variant":"link","size":"sm"},on:{"click":function($event){$event.stopPropagation();return _vm.updateApp(item.slug, { path: item.path })}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'save'],"size":"lg"}})],1)]}}])}),_c('infinite-loading',{on:{"infinite":_vm.infiniteHandler}},[_c('div',{attrs:{"slot":"spinner"},slot:"spinner"}),_c('div',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"},[_vm._v("No results")])]),_c('scroll-top-arrow')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }