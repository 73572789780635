<template>
  <b-container :key="componentKey">
    <b-overlay :show="loading">
      <b-row no-gutters class="mt-3">
        <b-col cols="4" md>
          <div class="body-title font-weight-bolder">Apps</div>
        </b-col>
        <b-col col md>
          <b-input-group>
            <b-input-group-prepend>
              <div class="input-group-text bg-white">
                <b-icon icon="search" aria-hidden="true" />
              </div>
            </b-input-group-prepend>
            <b-form-input
              v-model="search"
              ref="search"
              type="search"
              placeholder="Buscar..."
              class="border-left-0"
              :disabled="clearSearch"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <mn-table
        :items="items"
        :headers="headers"
        enabledHeader
        class="mt-3"
        @push-route="onPushRoute"
      >
        <template v-slot:avatar_url="{ item }">
          <section class="d-flex align-items-center">
            <b-avatar
              class="bg-gray-200 text-body"
              size="3em"
              rounded
              :src="item.avatar_url"
              :text="item.title | truncate(2) | upperCase"
            />
            <div v-if="item.project_type" class="ml-3">
              <icon-apple
                v-if="item.project_type === 'ios'"
                fillColor="rgba(0, 0, 0, 0.60)"
                :size="24"
              />
              <icon-android
                v-if="item.project_type === 'android'"
                fillColor="rgba(0, 0, 0, 0.60)"
                :size="24"
              />
            </div>
          </section>
        </template>

        <template v-slot:path="{ item }">
          <b-input-group size="sm" class="input-path">
            <b-input-group-prepend>
              <div class="input-group-text bg-white">
                /
              </div>
            </b-input-group-prepend>
            <b-form-input
              v-model="item.path"
              placeholder="Ingresar path"
              size="sm"
              class="border-left-0"
            />
          </b-input-group>
        </template>

        <template v-slot:actived="{ item }">
          <b-form-checkbox
            switch
            v-model="item.actived"
            @change="onActived($event, item.slug)"
          />
        </template>

        <template v-slot:actions="{ item }">
          <b-button
            v-b-tooltip.hover
            title="View Builds"
            variant="link"
            size="sm"
            :to="{ name: 'admin.builds', params: { app_slug: item.slug } }"
          >
            <font-awesome-icon :icon="['far', 'eye']" size="lg" />
          </b-button>
          <b-button
            v-b-tooltip.hover
            title="Save"
            variant="link"
            size="sm"
            @click.stop="updateApp(item.slug, { path: item.path })"
          >
            <font-awesome-icon :icon="['far', 'save']" size="lg" />
          </b-button>
        </template>
      </mn-table>

      <infinite-loading @infinite="infiniteHandler">
        <div slot="spinner"></div>
        <div slot="no-more"></div>
        <div slot="no-results">No results</div>
      </infinite-loading>

      <scroll-top-arrow />
    </b-overlay>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import MnTable from "@/components/common/table/MnTable";

export default {
  components: {
    MnTable
  },
  data() {
    return {
      componentKey: 0,
      loading: null,
      clearSearch: false,
      isInit: false,
      isSignIn: false,
      items: [],
      headers: [
        {
          name: "Avatar",
          field: "avatar_url",
          pushRoute: true,
          class: "cursor-pointer"
        },
        {
          name: "Title",
          field: "title",
          pushRoute: true,
          class: "cursor-pointer"
        },
        {
          name: "Path",
          field: "path"
        },
        {
          name: "Visible",
          field: "actived"
        },
        {
          name: "Actions",
          field: "actions"
        }
      ],
      pagination: {
        current_page: 1,
        per_page: 0,
        total_rows: 0
      },
      search: "",
      page: 1
    };
  },
  computed: {
    ...mapGetters({
      token: "auth/token"
    }),
    totalRows() {
      return this.pagination.total_rows;
    }
  },
  created() {
    this.loading = true;
    let checkGauthLoad = setInterval(() => {
      this.isInit = this.$gAuth.isInit;
      this.isSignIn = this.$gAuth.isAuthorized;

      if (this.isInit) {
        clearInterval(checkGauthLoad);
      }
    }, 1000);
  },
  watch: {
    isSignIn: "supervisor",
    search: "onSearch"
  },
  methods: {
    async supervisor(isSignIn) {
      if (!isSignIn) {
        this.$store.dispatch("auth/logout");
        this.$router.push({ name: "auth.login" });
        this.loading = false;
      }
    },
    async infiniteHandler($state) {
      let options = {
        page: 1
      };

      if (!this.pagination.app_slug_next && this.page > 1) {
        $state.complete();
        return null;
      }

      if (this.pagination.app_slug_next && this.page > 1) {
        options = {
          app_slug_next: this.pagination.app_slug_next,
          page: this.page
        };
      }

      const { data, meta } = await this.getAll(options);

      if (data.length) {
        this.items.push(...data);
        this.pagination = meta;
        $state.loaded();
        this.page += 1;
      } else {
        $state.complete();
      }
    },
    async getAll(options = {}) {
      try {
        this.loading = true;
        return await this.$store.dispatch("app/apps", options);
      } catch (error) {
        console.error("error", error);
        if (error.response.status === 403) {
          this.$store.dispatch("auth/logout");
          this.$router.push({ name: "auth.login" });
        }
      } finally {
        this.loading = false;
      }
    },
    onActived(event, app_slug) {
      this.updateApp(app_slug, { actived: event });
    },
    async updateApp(app_slug, data) {
      try {
        this.loading = true;
        const { message } = await this.$store.dispatch("app/updateApp", {
          app_slug,
          data
        });

        this.$bvToast.toast(message, {
          title: "Success",
          toaster: "b-toaster-bottom-right",
          solid: true,
          variant: "success",
          appendToast: true
        });
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.response.data.message.message, {
          title: "Error",
          toaster: "b-toaster-bottom-right",
          solid: true,
          variant: "danger",
          appendToast: true
        });

        if (error.response.status === 403) {
          await this.$gAuth.signOut();
          this.$store.dispatch("auth/logout");
          this.$router.push({ name: "auth.login" });
        }
      } finally {
        this.loading = false;
      }
    },
    onPushRoute(app_slug) {
      this.$router.push({
        name: "admin.builds",
        params: { app_slug }
      });
    },
    async onSearch(value) {
      if (value.length > 0) {
        setTimeout(async () => {
          const { data, meta } = await this.getAll({ search: value });
          this.pagination = meta;
          this.items = data;
        }, 200);
      } else {
        this.clearSearch = true;
        const { data, meta } = await this.getAll({ page: 1 });
        this.items = data;
        this.pagination = meta;
        this.$refs.search.focus();
        this.forceRerender();
      }
    },
    forceRerender() {
      this.componentKey += 1;
      this.clearSearch = false;
    },
    changePaginate(page) {
      if (page > this.pagination.current_page) {
        this.getAll({
          app_slug_next: this.pagination.app_slug_next,
          history: this.pagination.history.join(",")
        });
      } else {
        this.getAll({
          app_slug_next: this.pagination.app_slug_back,
          history: this.pagination.history.join(",")
        });
      }
    }
  }
};
</script>
